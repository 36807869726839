<template>
  <base-dialog
    width="500px"
    :visible="visible"
    @close="closeDialog"
    @click:outside="closeDialog"
    :title="$t('facility.rentalList.updateRentItem')"
    isGrayDialog
  >
    <template v-slot:content>
    <v-form ref="form">
        <div class="t-item">
          <label class="custom-text">{{ $t('facility.rentalList.itemID') }}</label>
          <label class="text-left custom-text">{{ getItem.id }}</label>
        </div>

        <div class="t-item custom-text">
          <label class=" custom-text mb-7">{{ $t('facility.rentalList.itemForRent') }}</label>
          <v-text-field
            v-model="getItem.name"
            :rules="[() => !!getItem.name || $t('facility.rentalList.rules.required'),
            () => getItem.name.length < maxInt ||$t('rules.isInvalid')]"
            dense
            outlined
            class="pa-0 input-pl-0"
          ></v-text-field>
        </div>

        <div class="t-item custom-text">
          <label class="custom-text mb-7">{{ $t('facility.rentalList.unitPrice') }}</label>
          <tvos-int-input jpy
            dense
            outlined
            class="pa-0"
            v-model="getItem.price"
            :min="0"
            :rules="[$rules.required]"
          ></tvos-int-input>
        </div>

        <div class="t-item custom-text">
          <label class="custom-text mb-7">{{ $t('facility.rentalList.paymentUnitPrice') }}</label>
          <v-select
            dense
            outlined
            color="var(--text__gray)"
            v-model="getItem.rentalPeriod"
            :items="paymentUnitPriceList"
            item-text="key"
            item-value="value"
          ></v-select>
        </div>
    </v-form>
      <div class="text-right px-5 mt-5">
        <label class="text--small" style="font-size: 13px !important; color: #666666">{{ $t('facility.rentalList.deleteNotice') }}</label>
        <br>
        <v-btn
          outlined
          color="#CA406A"
          class="my-5"
          @click="deleteRentalItemDef(); closeDialog()"
        >
          {{ $t('buttons.delete') }}
        </v-btn>
        <v-divider/>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-right mt-5 px-5">
        <v-btn
          large
          class="mr-5"
          color="#DB3069"
          @click="closeDialog"
        ><span style="font-size:17px !important;color: #fff; width:80px">{{ $t("buttons.cancel") }}</span></v-btn
        >
        <v-btn
          large
          color="#004F8B"
          @click="handleSubmit()"
        ><span style="font-size:17px !important;color: #fff; width:80px">{{ $t("buttons.save") }}</span></v-btn>
      </div>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { FACILITY_TYPE_IN } from '@/constants/enum'

export default {
  name: 'editRental',
  props: {
    visible: Boolean,
    itemID: {
      type: Number
    }
  },
  data () {
    return {
      maxInt: 256
    }
  },
  methods: {
    setNullPrice (price) {
      if (price !== null && price !== '') {
        return true
      } else return false
    },
    save () {
      this.$emit('close')
    },
    closeDialog () {
      this.$emit('close')
    },
    ...mapMutations({
      setRentalItem: 'setRentalItemDef'
    }),
    ...mapActions(['updateRentalItemDef', 'deleteRentalItemDef']),
    updateRentalPeriod () {
      const rentalItemDef = {
        id: this.getItem.id,
        rentalPeriod: this.getItem.rentalPeriod,
        name: this.getItem.name,
        price: this.getItem.price
      }
      this.setRentalItem({ rentalItemDef: rentalItemDef })
    },
    async handleSubmit () {
      if (this.$refs.form.validate()) {
        this.setNullPrice()
        this.updateRentalPeriod()
        await this.updateRentalItemDef()
        this.closeDialog()
      }
    }
  },
  computed: {
    ...mapGetters({
      getItem: 'rentalItemDef'
    }),
    paymentUnitPriceList () {
      return Object.keys(FACILITY_TYPE_IN).map(key => {
        return {
          key,
          value: FACILITY_TYPE_IN[key]
        }
      })
    }
  },
  components: {
    BaseDialog
  }
}
</script>

<style scoped lang="scss">
.t-table {
  display: table;
  border-spacing: 0 20px;
  &__row {
    display: table-row;
    label {
      padding-right: 15px;
      text-align: right;
      display: table-cell;
      white-space: nowrap;
    }
    .v-input {
      display: table-cell;
    }
  }
}
</style>
<style lang="scss">
.input-pl-0 {
  .v-text-field__slot {
    padding-left: 0 !important;
  }
}

input {
  font-size: 16px !important;
  color: #666666 !important
}
.custom-text {
  font-size: 16px !important;
  color: #666666 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
</style>
