<template>
  <BaseFacility>
  <template v-slot:title>
      <h1 class="py-4 page-title-main">
      {{ $t("routeTitles.facility.facility") }}
    </h1>
  </template>
    <template v-slot:default>
      <v-card class="pa-5 mt-10" width="800px">
        <v-card-title>
          <span style="font-size: 18px; color: #aaaaaa">{{ $t('facility.rentalList.listItemForRent') }}</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="header"
            :items="rentalList"
            hide-default-footer
            disable-sort
            disable-pagination
            class="table-custom"
          >
            <template v-slot:[`item.rentalPeriod`]="{ item }"> {{ item.rentalPeriod == 'Day' ? '一泊' : '一滞在' }} </template>
            <template v-slot:[`item.price`]="{ item }"> {{ item.price.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }) }} </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn
                :disabled="!checkPerUser"
                icon
                class="box-shadow-icon"
                color="var(--bt__blue-detail)"
                @click="itemID = item.id; isShowEditRental = true; rentalPeriod = item.rentalPeriod; name = item.name; price = item.price; setRentalItem()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-row class="mt-10 d-flex justify-end pb-8">
            <v-btn
              :disabled="!checkPerUser"
              color="var(--bt__congress-blue)"
              class="btn-create white--text"
              @click="checkPerUser ? isShowNewRental = true : ''"
            >
              {{ $t('facility.rentalList.createNewRentItem') }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <NewRental :visible="isShowNewRental" @close="newClose" />
      <EditRental :visible="isShowEditRental" :itemID="itemID" @close="editClose" />
    </template>
  </BaseFacility>
</template>

<script>
import BaseFacility from '@/components/Facility/BaseFacility'
import NewRental from './newRental'
import EditRental from './editRental'
import { RENTAL_ITEM_DEF_LIST } from '@/api/graphql/rental-item-def'
import { mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'

export default {
  name: 'RentalList',
  apollo: {
    rentalList: gql`${RENTAL_ITEM_DEF_LIST}`
  },
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      rentalPeriod: '',
      name: '',
      price: null,
      itemID: 0,
      isShowNewRental: false,
      isShowEditRental: false,
      rentalList: [],
      header: [
        { text: this.$t('facility.rentalList.itemID'), value: 'id' },
        { text: this.$t('facility.rentalList.itemForRent'), value: 'name' },
        { text: this.$t('facility.rentalList.unitPrice'), value: 'price' },
        { text: this.$t('common.paymentUnit'), value: 'rentalPeriod' },
        { text: '', value: 'edit', align: 'end' }
      ]
    }
  },
  methods: {
    ...mapMutations({
      setRentalItemDef: 'setRentalItemDef'
    }),
    setRentalItem () {
      const rentalItemDef = {
        id: this.itemID,
        rentalPeriod: this.rentalPeriod,
        name: this.name,
        price: parseInt(this.price)
      }
      this.setRentalItemDef({ rentalItemDef: rentalItemDef })
    },
    newClose () {
      this.isShowNewRental = false
      this.$apollo.queries.rentalList.refetch()
    },
    editClose () {
      this.isShowEditRental = false
      this.$apollo.queries.rentalList.refetch()
    }
  },
  components: {
    BaseFacility,
    NewRental,
    EditRental
  }
}
</script>

<style lang="scss" scoped>
.box-shadow-icon {
  box-shadow: 0 3px 6px #00000029 !important;
}
::v-deep {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          th {
            span {
              color: #757575 !important
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:nth-child(1) {
              font-size: 10px !important;
              color: #757575 !important;
            }
            &:not(:nth-child(1)) {
              font-size: 15px !important;
              color: #424242 !important;
            }
          }
        }
      }
    }
  }
}
.btn-create {
  width: 141px !important;
  height: 34px !important;
}
</style>
