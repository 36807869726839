<template>
  <base-dialog
    width="500px"
    :visible="visible"
    @close="closeDialog"
    @click:outside="closeDialog"
    :title="$t('facility.rentalList.createNewRentItem')"
    isGrayDialog
  >
    <template v-slot:content>
      <v-form ref="form">
           <div class="t-table px-5 mt-3">
        <div class="t-table__row">
          <label class="t-label custom-text">{{ $t('facility.rentalList.itemForRent') }}</label>
          <v-text-field
            dense
            outlined
            class="pa-0"
            v-model="faciltyRenItemName"
            :rules="[() => !!faciltyRenItemName ||$t('facility.rentalList.rules.required'),
                    () => faciltyRenItemName.length < maxInt ||$t('rules.isInvalid')]"
          ></v-text-field>
        </div>
        <div class="t-table__row">
          <label class="t-label custom-text">{{ $t('facility.rentalList.unitPrice') }}</label>
          <tvos-int-input jpy
            dense
            outlined
            class="pa-0"
            v-model="facilityPrice"
            :min="0"
            :rules="[$rules.required]"
          ></tvos-int-input>
        </div>

        <div class="t-table__row">
          <label class="t-label custom-text">{{ $t('facility.rentalList.paymentUnitPrice') }}</label>
          <v-select
            dense
            outlined
            color="var(--text__gray)"
            hide-details
            v-model="paymentUnitPrice"
            :items="paymentUnitPriceList"
          ></v-select>
        </div>
      </div>
      </v-form>
    </template>

    <template v-slot:footer>
      <div class="text-right mt-3 px-5">
        <v-btn
          large
          class="btn--pink mr-5"
          @click="closeDialog"
        ><span style="font-size: 17px">{{ $t("buttons.cancel") }}</span></v-btn
        >
        <v-btn
          large
          color="#004F8B"
          @click="handleSubmit()"
        ><span style="font-size: 17px; color: #fff">{{ $t("buttons.saveAndCreate") }}</span></v-btn>
      </div>
    </template>
  </base-dialog>
</template>

<script>

import BaseDialog from '@/components/Dialog/BaseDialog'
import { mapMutations, mapActions } from 'vuex'
import { FACILITY_TYPE_IN } from '@/constants/enum'

export default {
  name: 'newRental',
  props: {
    visible: Boolean
  },
  data () {
    return {
      paymentUnitPrice: Object.keys(FACILITY_TYPE_IN)[0],
      paymentUnitPriceList: [],
      facilityPrice: null,
      faciltyRenItemName: '',
      maxInt: 256
    }
  },
  methods: {
    save () {
      this.$emit('close')
    },
    closeDialog () {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.faciltyRenItemName = ''
      this.facilityPrice = null
    },
    ...mapMutations({
      setRentalItemDef: 'setRentalItemDef'
    }),
    ...mapActions({ actionCreate: 'createRentalItemDef' }),
    async createRentalItemDef () {
      const rentalItemDef = {
        rentalPeriod: FACILITY_TYPE_IN[this.paymentUnitPrice],
        name: this.faciltyRenItemName,
        price: parseInt(this.facilityPrice)
      }
      this.setRentalItemDef({ rentalItemDef: rentalItemDef })
      await this.actionCreate()
      this.closeDialog()
    },
    handleSubmit () {
      if (this.$refs.form.validate()) {
        this.createRentalItemDef()
      }
    }
  },
  mounted () {
    this.paymentUnitPriceList = Object.keys(FACILITY_TYPE_IN)
  },
  components: {
    BaseDialog
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  input {
    font-size: 16px !important;
    color: #666666 !important;
  }
  .v-select__slot {
    .v-select__selection--comma {
      font-size: 16px !important;
      color: #666666 !important;
    }
  }
}
.custom-text {
  font-size: 16px !important;
  color: #666666 !important;
}

.t-table {
  display: table;
  border-spacing: 0 20px;
  &__row {
    display: table-row;
    label {
      padding-right: 15px;
      text-align: right;
      display: table-cell;
      white-space: nowrap;
    }
    .v-input {
      display: table-cell;
    }
  }
}
</style>
